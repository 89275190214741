import * as React from "react"
import Base from "../components/Base"

import hero1 from "../images/Vendors Hero.png"
import OwnersBody1 from "../images/Vendors Body 1.jpg"
import OwnersBody2 from "../images/Vendors Body 2.jpg"
import Handyman1 from "../images/handyman3.svg"
import Handyman2 from "../images/handyman2.svg"
import icon1 from "../images/1.svg"
import icon2 from "../images/6.svg"
import icon3 from "../images/7.svg"
import icon4 from "../images/8.svg"
import icon5 from "../images/9.svg"

export default function VendorsPage() {
  return (
    <Base data={data} />
  )
}

const data = {
  heroData: {
    image: hero1,
    headline: "Don't sell without a plan.",
    subtitle: "Homeplan helps vendors increase the appeal and sale price of their property by offering home buyers pre-paid annual maintenance plans that cover the essential jobs for the home.",
    subtitle2: ""
  },
  introData: {
    tagline1: "Give your buyers peace of mind when purchasing your property.",
    image1: OwnersBody1,
    tagline2: "Stand out from the market and",
    tagline2High: "increase your sale price.",
    body: "Homeplan’s annual maintenance plans help cover the essential jobs for your property. We help to increase the appeal to buyers of your property and give you the best chance of achieving a great result.",
    bulletPunch: "Our maintenance plans offer:",
    bullets: ["A unique point of difference in the market", "A negotiation tool with buyers to increase your sale price", "Peace of mind for buyers knowing maintenance is taken care of", "Affordable added value to your property sale"],
    image2: Handyman1
  },
  howData: {
    hows: [
      { icon: icon1, text: "Sign your property up to offer our maintenance plan to your buyer" },
      { icon: icon2, text: "Choose to prepay a maintenance plan for either 1, 2, or 3 years" },
      { icon: icon3, text: "Our maintenance plan will be marketed with your property and given to home buyers" },
      { icon: icon4, text: "Your agent will use the plan to incentivise the sale and as a negotiation tool" },
      { icon: icon5, text: "You’ll achieve the best sale price for your property" },
    ],
    image: Handyman2,
    taglineHigh: "Don't sell",
    tagline: "without a plan."
  },
  outroData: {
    close: "We currently only offer our maintenance plans to vendors exclusively through our real estate agency partners across the Sydney Metro area.",
    image: OwnersBody2
  }
}